import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import MySelectFormItem from '../../../components/mySelectFormItem/MySelectFormItem';

const SelectUploadFileBankDataType = (props) => {
  const { withAll, ...otherProps } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    if (isMounted()) {
      let data = [
        { label: t('WORD_ACBA_FILE_XLS'), value: 'ACBA_BANK_XLS_FILE' },
      ];
      if (withAll) data.unshift({ label: t('WORD_ALL'), value: '' });
      setlist(data);
    }
  }, [isMounted, t, withAll]);

  return <MySelectFormItem {...otherProps} options={list} />;
};

export default SelectUploadFileBankDataType;
