import { Divider, Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import MyTextTitle from '../../../components/myText/MyTextTitle';
import MyTextSecondary from '../../../components/myText/MyTextSecondary';
import SocialLoginAppleButton from '../../../components/socialLoginAppleButton/SocialLoginAppleButton';
import SocialLoginGoogleButton from '../../../components/socialLoginGoogleButton/SocialLoginGoogleButton';
import MyCard from '../../../components/myCard/MyCard';
import ApiLocalStorage from '../../../redux/ApiLocalStorage';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import UtilFirebase from '../../../utils/UtilFirebase';
import { useLocation, useNavigate } from 'react-router-dom';
import UtilNotify from '../../../utils/UtilNotify';
import FormApp from '../../../components/formApp/FormApp';
import InputEmailFormItem from '../../../components/myInputForm/InputEmailFormItem';
import InputPasswordFormItem from '../../../components/myInputForm/InputPasswordFormItem';
import { useForm } from 'antd/es/form/Form';
import CheckBoxFormItem from '../../../components/myCheckbox/CheckBoxFormItem';
import MyLink from '../../../components/myLink/MyLink';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import MyCaptcha from '../../../components/myGoogleCaptcha/MyCaptcha';
import MySpace from '../../../components/mySpace/MySpace';
import md5 from 'md5';
import UsersActions from '../../../actions/UsersActions';
import LoadingIconApp from '../../../components/loadingIconApp/LoadingIconApp';
import UtilObject from '../../../utils/UtilObject';
import ApiStorage from '../../../redux/ApiStorage';
import { useTranslation } from 'react-i18next';
import './login.scss';

const formName = 'login_form';

const Login = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [form] = useForm();

  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);
  const [CaptchaCheck, setCaptchaCheck] = useState(false);
  const rememberMe = Form.useWatch('rememberMe', form);

  const handleAfterSuccessLogin = useCallback(
    async (obj) => {
      setsaving(true);
      let result = await UsersActions.login(obj);
      if (isMounted()) {
        if (result.success) {
          if (
            rememberMe ||
            obj.registerType !== UtilFirebase.REGISTERTYPE.OURSITE
          ) {
            obj.rememberMe = true;
            ApiLocalStorage.setItem(ApiLocalStorage.TEMP_REMEMBER_ME, obj);
          }
          if (!obj.verify2fasuccess && result.data.is2FAenabled) {
            navigate('/two_step_verification');
          } else {
            navigate('/home');
          }
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
          setsaving(false);
          setloading(false);
        }
      }
    },
    [isMounted, navigate, rememberMe]
  );

  useEffect(() => {
    if (
      (location && !location.state) ||
      (location.state.hasOwnProperty('force') && !location.state.force)
    ) {
      let obj = ApiLocalStorage.getItem(ApiLocalStorage.TEMP_REMEMBER_ME);
      console.log('obj :>> ', obj);
      if (!UtilObject.isEmpty(obj)) {
        obj.verify2fasuccess = true;
        form.setFieldValue({ rememberMe: obj.rememberMe });
        handleAfterSuccessLogin(obj);
      } else {
        ApiStorage.clearUser();
        setloading(false);
      }
    } else {
      setloading(false);
    }
  }, [location, handleAfterSuccessLogin, form]);

  const onFinish = async (values) => {
    setsaving(true);
    await handleAfterSuccessLogin({
      email: values.email,
      password: md5(values.password),
      registerType: UtilFirebase.REGISTERTYPE.OURSITE,
    });
  };

  if (loading) {
    return <LoadingIconApp />;
  }

  return (
    <MyCard className="login-card">
      <MySpace direction="vertical" style={{ marginBottom: 30 }}>
        <MyTextTitle size={30}>{t('WORD_LOGIN')}</MyTextTitle>
        <MyTextSecondary>
          {t('WORD_GAIN_TOTAL_CONTROL_OF_YOUR_MONEY')}
        </MyTextSecondary>
      </MySpace>
      <MySpace direction="vertical" size={'large'} fullWidth>
        <SocialLoginAppleButton
          onSuccess={handleAfterSuccessLogin}
          disabled={!CaptchaCheck}
          loading={saving}
        />
        <SocialLoginGoogleButton
          onSuccess={handleAfterSuccessLogin}
          disabled={!CaptchaCheck}
          loading={saving}
        />
      </MySpace>
      <Divider>
        <MyTextSecondary>or</MyTextSecondary>
      </Divider>
      <FormApp
        className={'form-wrapper'}
        form={form}
        name={formName}
        onFinish={onFinish}
        initialValues={{ rememberMe: true }}
      >
        <InputEmailFormItem name="email" placeholder="Email" />
        <InputPasswordFormItem name="password" placeholder="Password" />
        <div className="login-page-remomber-forgot-section">
          <CheckBoxFormItem name="rememberMe" label={'Remember me'} />
          <MyLink style={{ fontWeight: 600 }} path="/forgot_password">
            {t('WORD_FORGOT_PASSWORD')}
          </MyLink>
        </div>
        <MyButtonMain
          fullWidth
          label="Login"
          form={formName}
          disabled={!CaptchaCheck}
          loading={saving}
        />
      </FormApp>
      <MySpace style={{ marginTop: 20 }}>
        <MyTextSecondary>{t('WORD_DONT_HAVE_AN_ACCOUNT')}</MyTextSecondary>
        <MyLink style={{ fontWeight: 600 }} path="/register">
          {t('WORD_SIGN_UP')}
        </MyLink>
      </MySpace>
      <MyCaptcha
        onChange={() => {
          if (isMounted()) {
            setCaptchaCheck(true);
          }
        }}
      />
    </MyCard>
  );
};

export default Login;
