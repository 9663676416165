import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Select, Button } from 'antd';
import CategoriesActions from '../../actions/CategoriesActions';
import GetCategoryIcons from '../defaultBudgetCategories/GetCategoryIcons';
import UtilNotify from '../../utils/UtilNotify';
import MySpace from '../mySpace/MySpace';
import MyText from '../myText/MyText';

const { Option, OptGroup } = Select;

const SelectCategoryValue = (props) => {
  const {
    onChange,
    label,
    value,
    placeholder,
    onChangeItem,
    allowClear,
    disabled,
    onlySelect,
    transactionType,
    style,
  } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);
  const [loading, setloading] = useState(false);
  const [search, setSearch] = useState('');
  const [open, setopen] = useState(false);

  const fillFS = useCallback(async () => {
    if (t) {
      setloading(true);
      let result = await CategoriesActions.fillPaginationList({
        search: '',
        filter: {
          // type: CategoriesActions.TYPE.DEFAULT,
          typeIncomeExpense: transactionType,
        },
      });
      if (isMounted()) {
        if (result.success) {
          let data = result.data
            .filter((cat) => {
              return cat.typeMainChild === CategoriesActions.TYPEMAINCHILD.MAIN;
            })
            .map((cate) => {
              return (
                <OptGroup key={cate?.idCategory} label={cate?.categoryName}>
                  {result.data
                    ?.filter((cat) => {
                      return (
                        cat.typeMainChild ===
                          CategoriesActions.TYPEMAINCHILD.CHILD &&
                        cat.idParent === cate.idCategory
                      );
                    })
                    .map((catc) => {
                      return (
                        <Option
                          {...catc}
                          key={catc.idCategory}
                          value={catc.idCategory}
                          label={catc.categoryName}
                        >
                          <MySpace fullWidth spaceBetween>
                            <MySpace>
                              <GetCategoryIcons icon={catc.icon} />
                              <MyText>{catc.categoryName}</MyText>
                            </MySpace>
                            <div className="option-item-value">
                              {catc.currentAvilableFinal}
                            </div>
                          </MySpace>
                        </Option>
                      );
                    })}
                </OptGroup>
              );
            });
          let data2 = result.data
            .filter((cat) => {
              return (
                cat.typeMainChild === CategoriesActions.TYPEMAINCHILD.CHILD &&
                cat.idParent === 0
              );
            })
            .map((catc) => {
              return (
                <Option
                  {...catc}
                  key={catc.idCategory}
                  value={catc.idCategory}
                  label={catc.categoryName}
                >
                  {catc.categoryName}{' '}
                </Option>
              );
            });
          setlist([...data, ...data2]);
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
        setloading(false);
      }
    }
  }, [t, isMounted, transactionType]);

  useEffect(() => {
    fillFS();
  }, [fillFS]);

  const handleOnChange = (e, ee) => {
    setSearch('');
    onChange && onChange(e);
    onChangeItem && onChangeItem(ee);
  };

  const handleCreate = async () => {
    setloading(true);
    let result = await CategoriesActions.insert({
      categoryName: search,
      typeMainChild: CategoriesActions.TYPEMAINCHILD.CHILD,
      idParent: 0,
      icon: '',
      categoryNote: '',
      ordering: 1000,
    });
    if (isMounted()) {
      if (result.success) {
        setlist((ov) => {
          let add = (
            <Option
              {...result.data}
              key={result.data.idCategory}
              value={result.data.idCategory}
              label={result.data.categoryName}
            >
              {result.data.categoryName}{' '}
            </Option>
          );
          ov.push(add);
          return ov;
        });
        handleOnChange(result.data.idCategory, result.data);
        setopen(false);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    }
  };

  return (
    <Select
      style={{ width: '100%', ...style }}
      placeholder={placeholder || label || ''}
      onChange={handleOnChange}
      value={value}
      onSearch={setSearch}
      showSearch={true}
      open={open}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      optionFilterProp="label"
      loading={loading}
      allowClear={allowClear}
      disabled={disabled}
      onDropdownVisibleChange={setopen}
      dropdownRender={(menu) => {
        let find = list.find((it) => {
          return (
            it.props.label.indexOf(search) ||
            it.props.children?.find((itc, index) => {
              return itc?.props?.categoryName?.indexOf(search);
            })
          );
        });
        return (
          <>
            {find || !search ? (
              menu
            ) : !onlySelect ? (
              <Button onClick={handleCreate} style={{ padding: 5 }}>
                <PlusCircleOutlined />
                {`  Create "${search}" Category`}
              </Button>
            ) : null}
          </>
        );
      }}
    >
      {list &&
        list.map((oo) => {
          return oo;
        })}
    </Select>
  );
};

export default SelectCategoryValue;
