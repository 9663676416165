import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import MyPageHeader from '../../../components/myPageHeader/MyPageHeader';
import { useNavigate } from 'react-router-dom';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import MySpace from '../../../components/mySpace/MySpace';
import FormApp from '../../../components/formApp/FormApp';
import { useForm } from 'antd/es/form/Form';
import UploadFilesManuelStart from '../../../components/uploads/UploadFilesManuelStart';
import MyTable from '../../../components/myTable/MyTable';
import MyText from '../../../components/myText/MyText';
import UtilDate from '../../../utils/UtilDate';
import SelectAccount from '../../../components/selects/SelectAccount';
import UtilNumber from '../../../utils/UtilNumber';
import { Form } from 'antd';
import InputTextFormItem from '../../../components/myInputForm/InputTextFormItem';
import MyButtonSecondary from '../../../components/myButton/MyButtonSecondary';
// import SelectPayee from '../../../components/selects/SelectPayee';
import SelectUploadFileBankDataType from './SelectUploadFileBankDataType';
import TransactionActions from '../../../actions/TransactionActions';
import UtilNotify from '../../../utils/UtilNotify';
import { SaveOutlined } from '@ant-design/icons';
import SelectCategoryValue from '../../../components/selects/SelectCategoryValue';
// import LoadingIconApp from '../../../components/loadingIconApp/LoadingIconApp';

const formName = 'import-xls-form';

const TransactionImportXls = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const [form] = useForm();

  const inputRef = useRef();

  const [listData, setlistData] = useState([]);
  const [loading, setloading] = useState(false);
  const [saving, setsaving] = useState(false);

  const idAccount = Form.useWatch('idAccount', form);
  const accountName = Form.useWatch('accountName', form);

  const columns = [
    {
      title: t('WORD_INDEX'),
      key: 'Index',
      width: 50,
      render: (value, record, index) => {
        return <MyText>{index + 1}</MyText>;
      },
    },
    {
      title: t('WORD_DATE'),
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      width: 120,
      render: (value, record) => {
        return <MyText>{UtilDate.formatDate(value)}</MyText>;
      },
    },
    {
      title: t('WORD_ACCOUNT'),
      dataIndex: 'accountName',
      key: 'accountName',
      width: 120,
      render: (value, record) => {
        // if (editingRow === record?.idKey) {
        //   return (
        //     <SelectAccount
        //       name="idAccount"
        //       style={{ width: 130 }}
        //       required
        //       disabled={!canEdit}
        //       onChangeItem={(ii) => {
        //         if (ii) {
        //           form.setFieldsValue({ accountName: ii.accountName });
        //         }
        //       }}
        //     />
        //   );
        // } else {
        return <MyText>{value}</MyText>;
        // }
      },
    },
    // {
    //   title: 'Payee',
    //   dataIndex: 'payeeName',
    //   key: 'payeeName',
    //   width: 130,
    //   render: (value, record) => {
    //     // if (editingRow === record?.idKey) {
    //     return <SelectPayee name="idPayee" />;
    //     // } else {
    //     // return <MyText>{value}</MyText>;
    //     // }
    //   },
    // },
    {
      title: 'Memo',
      dataIndex: 'transactionNote',
      key: 'transactionNote',
      render: (value) => {
        // if (editingRow === record?.idKey) {
        //   return (
        //     <InputTextFormItem name="transactionNote" placeholder="Memo" />
        //   );
        // } else {
        return <MyText>{value}</MyText>;
        // }
      },
    },
    {
      title: 'Inflow',
      dataIndex: 'valueIncome',
      key: 'valueIncome',
      width: 130,
      render: (value, record) => {
        //   if (editingRow === record?.idKey) {
        //     return (
        //       <>
        //         <InputTextFormItem name="idKey" hidden={true} />
        //         <InputTextFormItem name="categoryName" hidden={true} />
        //         <InputTextFormItem name="accountName" hidden={true} />
        //         <InputNumberFormItem name="value" hidden={true} />
        //         <InputNumberFormItem name="idTransaction" hidden={true} />
        //         <InputNumberFormItem name="valueIncome" placeholder="Inflow" />
        //       </>
        //     );
        //   } else {
        return <MyText>{value ? UtilNumber.formatDouble(value) : ''}</MyText>;
        //   }
      },
    },
    {
      title: 'Outflow',
      dataIndex: 'valueExpense',
      key: 'valueExpense',
      width: 130,
      render: (value, record) => {
        //   if (editingRow === record?.idKey) {
        //     return (
        //       <InputNumberFormItem
        //         name="valueExpense"
        //         placeholder="Outflow"
        //         onChange={(value) => {
        //           form.setFieldsValue({ valueExpense: value });
        //         }}
        //       />
        //     );
        //   } else {
        return <MyText>{value ? UtilNumber.formatDouble(value) : ''}</MyText>;
        //   }
      },
    },
    {
      title: 'Category',
      dataIndex: 'idCategory',
      key: 'idCategory',
      width: 220,
      render: (value, { disabled, categoryName }, index) => {
        if (disabled) {
          return <MyText>{categoryName}</MyText>;
        }
        return (
          <SelectCategoryValue
            style={{ width: 220 }}
            value={value || ''}
            label={t('WORD_CATEGORY')}
            placeholder="Category"
            onChangeItem={(ii) => {
              if (ii) {
                console.log('ii :>> ', ii);
                setlistData((ov) => {
                  return ov.map((oo, i) => {
                    if (i === index) {
                      oo.idCategory = ii.idCategory;
                      oo.categoryName = ii.categoryName;
                    }
                    return oo;
                  });
                });
              }
            }}
          />
        );
        // }
        // else {
        // return (
        //   <MyText>
        //     {accountNameTo ? `Transfer to ${accountNameTo}` : categoryName}
        //   </MyText>
        // );
        // }
      },
    },
    {
      title: 'Save',
      dataIndex: 'disabled',
      key: 'disabled',
      width: 60,
      render: (value, row, index) => {
        return (
          <MyButtonSecondary
            loading={saving}
            // label={t('WORD_SAVE')}
            icon={<SaveOutlined />}
            disabled={value}
            onClick={async () => {
              const result = await TransactionActions.insert(row);
              if (result.success) {
                setlistData((ov) => {
                  return ov.map((oo, i) => {
                    if (i === index) {
                      Object.assign(oo, result.data[0]);
                      oo.disabled = true;
                    }
                    return oo;
                  });
                });
              }
            }}
          />
        );
      },
    },
    // {
    //   title: 'Cleared',
    //   dataIndex: 'approved',
    //   key: 'approved',
    //   width: 15,
    //   render: (value, record) => {
    //     // if (editingRow === record?.idKey) {
    //     //   return <MySwitchFormItem name="approved" />;
    //     // } else {
    //     return Boolean(value) ? null : <HourglassSVG />;
    //     // }
    //   },
    // },
  ];

  const onFinish = async (values) => {
    console.log('values :>> ', values);
    setloading(true);
    const URL_IMPORT_XLS = 'cdb/transactions/importTransactioFromXls';
    await inputRef.current.startUpload(URL_IMPORT_XLS, values);
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleSave = async () => {
    setsaving(true);
    const result = await TransactionActions.saveImportFromXls(
      listData.filter((oo) => !oo.disabled)
    );
    console.log('listData :>> ', listData);
    console.log('result :>> ', result);
    setsaving(false);
    if (result.success) {
      UtilNotify.notifySuccess('WORD_SAVED_SUCCESSFULY');
      goBack();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  return (
    <>
      <MyPageHeader onBack={goBack} title={t('WORD_TRANSACTION_IMPORT_XLS')} />
      <MySpace direction="vertical" size="middle">
        <br />
        {listData?.length ? (
          <MyButtonSecondary
            loading={saving}
            label={t('WORD_SAVE')}
            onClick={handleSave}
          />
        ) : (
          <>
            <FormApp
              name={formName}
              form={form}
              onFinish={onFinish}
              initialValues={{ bankFileType: 'ACBA_BANK_XLS_FILE' }}
            >
              <SelectUploadFileBankDataType
                name="bankFileType"
                label={t('WORD_FILE_TYPE')}
                required
                disabled={loading}
              />
              <UploadFilesManuelStart
                ref={inputRef}
                className="file-xml-upload"
                name="userfile"
                fileTypes={['.xls']}
                label={t('WORD_FILE')}
                maxCount={1}
                disabled={loading}
                required
                onFail={() => {
                  setloading(false);
                }}
                onChange={(result) => {
                  if (isMounted()) {
                    setlistData(
                      result.data.map((oo) => {
                        return Object.assign(oo, {
                          idAccount: idAccount,
                          accountName: accountName,
                        });
                      })
                    );
                    setloading(false);
                  }
                }}
              />
              <div>
                <SelectAccount
                  name="idAccount"
                  label={t('WORD_ACCOUNT')}
                  required
                  disabled={loading}
                  onChangeItem={(acc) => {
                    form.setFieldsValue({ accountName: acc.accountName });
                  }}
                />
                <InputTextFormItem hidden name="accountName" />
              </div>
            </FormApp>
            <MyButtonMain
              loading={loading}
              form={formName}
              label={t('WORD_IMPORT')}
              fullWidth
            />
          </>
        )}
      </MySpace>
      <FormApp>
        <MyTable
          columns={columns}
          dataSource={listData}
          rowKey="idKey"
          loading={loading}
        />
      </FormApp>
    </>
  );
};

export default TransactionImportXls;
